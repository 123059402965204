import { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import TransitionComponent from "../components/Transition";

export default function Router({ routes }) {
  const routeItems = routes.map(({ path, name, Component }) => (
    <Route
      key={name}
      path={path}
      element={
        <TransitionComponent>
          <Component />
        </TransitionComponent>
      }
    />
  ));

  return (
    <Suspense>
      <Routes>{routeItems}</Routes>
    </Suspense>
  );
}

import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { useLocation } from "react-router-dom";

import "../../css/components/cursor.scss";

export default function Cursor() {
  const cursorDot = useRef();
  const cursorPath = useRef();

  function moveCursorDot(event) {
    if (cursorDot.current) {
      gsap.to(cursorDot.current, {
        x: event.clientX,
        y: event.clientY,
        delay: 0.02,
        ease: "back.out(2)",
        duration: 0.3,
      });
    }
  }

  function hideCursor() {
    gsap.to(cursorDot.current, {
      scale: 0,
      duration: 0.3,
    });
  }

  function showCursor() {
    gsap.to(cursorDot.current, {
      scale: 1,
      duration: 0.3,
    });
  }

  function cursorLink() {
    gsap.to(cursorPath.current, {
      fill: "white",
      rotate: 45,
      duration: 0.6,
    });
  }
  function cursorLinkRevert() {
    gsap.to(cursorPath.current, {
      fill: "transparent",
      rotate: 0,
      duration: 0.6,
    });
  }

  function cursorNero() {
    gsap.to(cursorPath.current, {
      stroke: "black",
      duration: 0.3,
    });
  }
  function cursorNeroRevert() {
    gsap.to(cursorPath.current, {
      stroke: "white",
      duration: 0.3,
    });
  }

  const location = useLocation();

  useEffect(() => {
    cursorLinkRevert();
    cursorNeroRevert();
  }, [location]);

  useEffect(() => {
    function mouseOverHandler(e) {
      let element = e.target;

      while (element) {
        if (
          element instanceof HTMLAnchorElement ||
          (element.classList && element.classList.contains("cursor-click")) ||
          (element.classList && element.classList.contains("swiper-button-prev")) ||
          (element.classList && element.classList.contains("swiper-button-next")) ||
          (element.type === "submit" && !element.disabled)
        ) {
          cursorLink();
        } else if (element.classList && element.classList.contains("fondo-bianco")) {
          cursorNero();
        }

        if (
          element.type === "text" ||
          element.type === "email" ||
          element.type === "checkbox" ||
          element.type === "textarea"
        ) {
          hideCursor();
          break;
        }

        element = element.parentNode;
      }
    }

    function mouseOutHandler(e) {
      let element = e.target;

      while (element) {
        if (
          element instanceof HTMLAnchorElement ||
          (element.classList && element.classList.contains("cursor-click")) ||
          (element.classList && element.classList.contains("swiper-button-prev")) ||
          (element.classList && element.classList.contains("swiper-button-next")) ||
          element.type === "submit"
        ) {
          cursorLinkRevert();
        } else if (element.classList && element.classList.contains("fondo-bianco")) {
          cursorNeroRevert();
        }

        if (
          element.type === "text" ||
          element.type === "email" ||
          element.type === "checkbox" ||
          element.type === "textarea"
        ) {
          showCursor();
          break;
        }

        element = element.parentNode;
      }
    }

    document.body.addEventListener("mousemove", function (event) {
      moveCursorDot(event);
    });
    document.body.addEventListener("mouseover", mouseOverHandler, false);
    document.body.addEventListener("mouseout", mouseOutHandler, false); // Cleanup function
  }, []);

  return (
    <div
      id="cursor-dot"
      ref={cursorDot}
    >
      <div className="dot">
        <svg
          width="100%"
          height="100%"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 35.48 35.48"
        >
          <path
            ref={cursorPath}
            d="m0,17.79c.73-.61,1.39-1.23,1.98-1.87.51-.54.97-1.13,1.38-1.77.4-.67.61-1.33.61-1.97l2.64,2.06h7.76v-7.53c-.22-.26-.44-.5-.66-.72-.18-.16-.42-.32-.72-.48-.26-.16-.53-.24-.83-.24L17.66,0c.62.74,1.27,1.39,1.93,1.97.55.51,1.16.97,1.82,1.39.7.38,1.38.58,2.04.58l-2.2,2.78v7.53h7.59s.5-.27.72-.53c.18-.19.35-.42.5-.67.15-.29.22-.58.22-.86l5.23,5.51c-.73.64-1.38,1.28-1.93,1.92-.51.54-.97,1.17-1.38,1.87-.37.67-.55,1.33-.55,1.97l-2.81-2.35h-7.59v7.77s.35.53.61.72c.22.19.46.37.72.53.29.13.59.19.88.19l-5.61,5.18c-.59-.7-1.21-1.34-1.87-1.92-.55-.48-1.17-.93-1.87-1.34-.66-.42-1.3-.62-1.93-.62l2.2-2.73v-7.77h-7.76c-.29.22-.53.46-.72.72-.18.22-.35.48-.5.77-.11.26-.17.54-.17.86L0,17.79Z"
          />
        </svg>
      </div>
    </div>
  );
}

import { lazy } from "react";
import Header from "./js/layout/Header";
import Router from "./js/routes/Router";
import Cursor from "./js/components/Cursor";
import ScrollToTop from "./js/components/ScrollToTop";
import CacheBuster from "react-cache-buster";
import packageInfo from "../package.json";

import "./css/base/reset.scss";
import "./css/base/mixins.scss";
import "./css/base/variables.scss";
import "./css/base/typography.scss";

function App() {
  const isProduction = process.env.NODE_ENV === "production";

  const CarminaNoctis = lazy(() => import("./js/pages/carminanoctis/CarminaNoctis"));
  const LeTrame = lazy(() => import("./js/pages/letrame/LeTrame"));
  const ILuoghi = lazy(() => import("./js/pages/iluoghi/ILuoghi"));
  const LaTroupe = lazy(() => import("./js/pages/latroupe/LaTroupe"));
  const ComeGiocare = lazy(() => import("./js/pages/comegiocare/ComeGiocare"));
  const Regolamento = lazy(() => import("./js/pages/regolamento/Regolamento"));
  const Contatti = lazy(() => import("./js/pages/contatti/Contatti"));

  const routes = [
    { path: "/", name: "Carmina Noctis", Component: CarminaNoctis },
    {
      path: "/come-giocare",
      name: "Come Giocare",
      Component: ComeGiocare,
    },
    {
      path: "/le-trame",
      name: "Le Trame",
      Component: LeTrame,
    },
    {
      path: "/i-luoghi",
      name: "I Luoghi",
      Component: ILuoghi,
    },
    {
      path: "/la-troupe",
      name: "La Troupe",
      Component: LaTroupe,
    },
    {
      path: "/regolamento",
      name: "Regolamento",
      Component: Regolamento,
    },
    {
      path: "/contatti",
      name: "Contatti",
      Component: Contatti,
    },
  ];
  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <div>
        <Header routes={routes} />
        <main id="smooth-wrapper">
          <div id="smooth-content">
            <Router routes={routes} />
          </div>
        </main>
        <Cursor />
        <ScrollToTop />
      </div>
    </CacheBuster>
  );
}

export default App;

import { useEffect, useRef } from "react";
import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { ScrollSmoother } from "gsap/ScrollSmoother";

import { mediaQueryAllMobile } from "../helpers/globals";

import { ReactComponent as Freccia } from "../../assets/freccia.svg";

import "../../css/components/scroll-to-top.scss";

export default function ScrollToTop() {
  const ctx = useRef();
  const scrollToTopRef = useRef();
  gsap.registerPlugin(ScrollToPlugin);
  gsap.registerPlugin(ScrollSmoother);

  useEffect(() => {
    ctx.current = gsap.context(() => {
      window.addEventListener("scroll", scrollFunction);
      function scrollFunction() {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
          gsap.to(scrollToTopRef.current, { autoAlpha: 1 });
        } else {
          gsap.to(scrollToTopRef.current, { autoAlpha: 0 });
        }
      }

      const tapEvent = window.ontouchstart === null ? "touchstart" : "click";

      scrollToTopRef.current.addEventListener(tapEvent, function (e) {
        e.preventDefault();
        if (mediaQueryAllMobile) {
          gsap.to(window, { duration: 1, scrollTo: { y: 0 } });
        } else {
          let smoother = ScrollSmoother.get();
          smoother.scrollTo(0, true);
        }
      });
    }, scrollToTopRef);

    return () => ctx.current.revert();
  }, []);

  return (
    <div
      id="scrollTopBtn"
      className="cursor-click"
      ref={scrollToTopRef}
    >
      <Freccia />
    </div>
  );
}
